import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Seo from "../components/seo"
import EmptyPage from "../components/EmptyPage"
import { renderHtmlBlocks } from "../helpers/render"
import { getCustomStyles } from "../helpers/styles"

const NotFoundPage = ({ data, location }) => {
  const { t } = useTranslation()
  const siteTitle = data.site.siteMetadata.title
  const content = data?.wpPage?.content
  const seo = data?.wpPage?.seo
  const settings = data?.wpPage?.settings

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={seo?.title || t("404: Страница не найдена")}
        description={seo?.metaDescription || ""}
        keywords={seo?.metaKeywords || ""}
      />
      <EmptyPage />
      {getCustomStyles(settings)}
      {content && renderHtmlBlocks(content)}
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query ($language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "404-not-found" }, status: { eq: "publish" }) {
      content
      seo {
        metaDescription
        metaKeywords
        title
      }
      settings {
        customStyles
        rbeaStyles
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
