import React from "react"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"

import {
  EmptyPageMain,
  EmptyPageBlock1,
  EmptyPageBlock2,
} from "../../assets/images"
import * as styles from "./emptyPage.module.css"

const EmptyPage = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.containerDecoration}>
          <img src={EmptyPageBlock1} alt="" />
        </div>
        <div className={styles.inner}>
          <div className={styles.mainImage}>
            <img src={EmptyPageMain} alt="" />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{t("увы, страница была удалена")}</div>
          <div className={styles.link}>
            <Link className={styles.button} to="/">
              {t("Перейти на главную")}
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.wrapperDecoration}>
        <img src={EmptyPageBlock2} alt="" />
      </div>
    </div>
  )
}

export default EmptyPage
