// extracted by mini-css-extract-plugin
export var button = "emptyPage-module--button--168c6";
export var container = "emptyPage-module--container--13722";
export var containerDecoration = "emptyPage-module--containerDecoration--2483e";
export var content = "emptyPage-module--content--47da1";
export var inner = "emptyPage-module--inner--58d61";
export var link = "emptyPage-module--link--357d2";
export var mainImage = "emptyPage-module--mainImage--ed95b";
export var title = "emptyPage-module--title--fbb4d";
export var wrapper = "emptyPage-module--wrapper--acaba";
export var wrapperDecoration = "emptyPage-module--wrapperDecoration--2b4ec";